import ReactCurvedText from "react-curved-text";

const CurvedText = ({ title, front }) => {
  const curvedTextProps = front
    ? {
        width: 311,
        height: 300,
        cx: 155.5,
        cy: 118.5,
        rx: 150,
        ry: 150,
        reversed: false,
        textPathProps: { startOffset: "1%" },
      }
    : {
        width: 311,
        height: 300,
        cx: 145.5,
        cy: 250.5,
        rx: 150,
        ry: 150,
        reversed: true,
        textPathProps: { startOffset: "11.5%" },
      };
  return (
    <ReactCurvedText

      {...curvedTextProps}
      startOffset={0}
      text={title || ""}
      textProps={{
        style: {
          fontSize: 28,
          fill: "black",
          fontWeight: "bold",
          letterSpacing: "2px",
        },
      }}
      tspanProps={null}
      ellipseProps={null}
      svgProps={null}
    />
  );
};

export default CurvedText;