import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Translate } from "translate-easy";
import Aos from "aos";
import "aos/dist/aos.css";
import background from "../../images/Vector.svg";
import Loading from "../Loading";
import { useNavigate } from "react-router-dom";

const Product = ({ isLoading, productsData }) => {

  const swiperRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="relative py-12 dark:bg-[--black]" id="products">
      <div className="absolute inset-0 z-0">
        <img
          src={background}
          alt="Background"
          className="w-full md:w-[45vw] absolute top-36 object-cover"
        />
      </div>
      <div className="relative z-10 p-4 md:p-8" ref={swiperRef}>
        <h2 className="text-2xl md:text-4xl font-bold my-5 tracking-[1rem] text-center text-[--text]">
          <Translate>Products</Translate>
        </h2>
        <Swiper
          spaceBetween={30}
          loop={true}
          pagination={{ clickable: true }}
          navigation={{
            prevEl: ".swiper-button-prev-custom",
            nextEl: ".swiper-button-next-custom",
          }}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          modules={[Navigation, Pagination, Autoplay]}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
          // className="mySwiper h-[70vh] w-[80vw] md:w-[80vw] d-flex items-center justify-between max-tab:justify-center "
          className="w-[65%] h-[80vh] max-md:w-[85%] max-tab:w-[95%] mx-auto mt-5 mb-0 p-2 "
        >
          {productsData?.products.map((product) => (
            <SwiperSlide key={product._id} className="relative z-50 d-flex justify-center items-center">
              <div className="relative mt-8 md:mt-16 w-[18vw] max-md:w-[24vw] max-tab:w-[35vw] 
              max-mob:w-[55vw] max-mob2:w-[60vw] max-mob3:w-[65vw]
               bg-[--black] h-[40vh] md:h-[50vh] 
              shadow-md shadow-gray-700 dark:shadow-white rounded-tl-full rounded-tr-full "
                data-aos="flip-left"
              >
                <img
                  src={product.cover}
                  alt="Product"
                  className="w-full h-[30vh] md:h-[40vh] -top-10 object-contain relative"
                />
                <div className="absolute bottom-0 left-0 right-0 text-center py-2 md:py-5 px-3 md:px-6 rounded-b-lg shadow-md">
                  <h2 className="text-sm md:text-lg font-medium text-[--text] my-4">
                    <Translate>{product.name}</Translate>
                  </h2>
                </div>
                <button onClick={() => navigate(`/products/${product._id}`)} className="absolute -bottom-4 md:-bottom-6 left-1/2 transform -translate-x-1/2 w-32 md:w-40 bg-pink-100 hover:bg-pink-400 font-bold py-2 md:py-3 rounded-full">
                  <Translate>{product.buttonText || "Shop Now"}</Translate>
                </button>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Product;
