import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Translate } from "translate-easy";
import Loading from "../Loading";
import Aos from "aos";
import "aos/dist/aos.css";
function CategoriesCard({ categoriesData, isLoading }) {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init();
  }, []);
  if (isLoading) {
    return <Loading />;
  }
  return (
    <div
      className="relative dark:bg-[--black]  p-[1rem] md:p-8 max-mob:p-0 lg:py-20 xl:py-16 "
      id="categories"
    >

      <div className="absolute bottom-24 md:bottom-96 transform -translate-y-1/2 left-4
       md:left-16 z-50 custom-prev cursor-pointer ">
        <IoIosArrowBack size={24} className="font-bold" />
      </div>
      <div className="absolute bottom-24 md:bottom-96 transform -translate-y-1/2 right-4
       md:right-16 z-50 custom-next cursor-pointer ">
        <IoIosArrowForward size={24} className="font-bold" />
      </div>

      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        loop={true}
        pagination={{ clickable: true }}
        navigation={{
          prevEl: ".custom-prev",
          nextEl: ".custom-next",
        }}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        modules={[Navigation, Pagination, Autoplay]}
        className="h-[80vh] md:h-[100vh] "
      >
        {categoriesData?.categories?.map((category, index) => (
          <SwiperSlide
            key={index}
            className="relative dark:bg-[#191919f3]  p-4 md:p-8 rounded-lg"
          >
            <div className="absolute bottom-0 left-0 right-0 grid grid-cols-4">
              <div style={{ backgroundColor: categoriesData.finger1 }}
                data-aos="fade-up" data-aos-duration="1000"
                className={`   hidden lg:block h-40 md:h-80 w-10 md:w-20 absolute -top-[50vh] md:-top-[100vh] right-[25%] md:right-[42%] xl:right-[30%] shadow-md shadow-[#541519] rounded-full `}
              ></div>
              <div style={{ backgroundColor: categoriesData.finger2 }}
                data-aos="fade-up" data-aos-duration="1100"
                className={`   hidden lg:block h-40 md:h-80 w-10 md:w-20 absolute -top-[45vh] md:-top-[90vh] right-[18%] md:right-[32%] xl:right-[24%] shadow-md shadow-[#541519] rounded-full  `}
              ></div>
              <div style={{ backgroundColor: categoriesData.finger2 }}
                data-aos="fade-up" data-aos-duration="1200"
                className={`   hidden lg:block h-40 md:h-80 w-10 md:w-20 absolute -top-[50vh] md:-top-[100vh] right-[11%] md:right-[22%] xl:right-[18%] shadow-md shadow-[#541519] rounded-full  `}
              ></div>
              <div style={{ backgroundColor: categoriesData.finger2 }}
                data-aos="fade-up" data-aos-duration="1300"
                className={`    hidden lg:block h-32 md:h-60 w-10 md:w-20 absolute -top-[42vh] md:-top-[85vh] right-[6%] md:right-[12%] xl:right-[12%] shadow-md shadow-[#541519] rounded-full   `}
              ></div>
            </div>
            <div className="absolute bottom-0 left-0 right-0 grid grid-cols-4">
              <div style={{ backgroundColor: categoriesData.finger2 }}
                data-aos="fade-down" data-aos-duration="1000"
                className={`  hidden lg:block h-20 md:h-40 w-10 md:w-20 absolute -top-[20vh] md:-top-[40vh] right-[25%] md:right-[42%] xl:right-[30%] shadow-md shadow-[#541519] rounded-full `}
              ></div>
              <div style={{ backgroundColor: categoriesData.finger2 }}
                data-aos="fade-down" data-aos-duration="1100"
                className={`   hidden lg:block h-32 md:h-60 w-10 md:w-20 absolute -top-32 md:-top-64 right-[18%] md:right-[32%] shadow-md xl:right-[24%] shadow-[#541519] rounded-full   `}
              ></div>
              <div style={{ backgroundColor: categoriesData.finger2 }}
                data-aos="fade-down" data-aos-duration="1200"
                className={`  hidden lg:block h-40 md:h-80 w-10 md:w-20 absolute -top-[30vh] md:-top-[60vh] right-[11%] md:right-[22%] shadow-md xl:right-[18%] shadow-[#541519] rounded-full   `}
              ></div>
              <div style={{ backgroundColor: categoriesData.finger2 }}
                data-aos="fade-down" data-aos-duration="1300"
                className={`   hidden lg:block h-32 md:h-64 w-10 md:w-20 absolute -top-[21vh] md:-top-[43vh] right-[6%] md:right-[12%] shadow-md xl:right-[12%] shadow-[#541519] rounded-full   `}
              ></div>
            </div>
            {/* Content */}
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[65%] flex flex-col items-left">
              <h2 data-aos="zoom-in-up" className="xl:text-7xl text-3xl md:text-5xl sm:text-2xl font-bold text-[#541519] dark:text-white -mb-1 ms-2 md:ms-4">
                <Translate>{categoriesData?.title?.split(" ")[0]}</Translate>
                <br />
                <Translate>{categoriesData?.title?.split(" ")[1]}</Translate>
              </h2>
              <div className="bg-white rounded-full w-[60vw] md:w-[70vw] h-[30vh] md:h-[45vh] 
              max-mob:w-[80vw] max-mob:h-[45vh]
               shadow-lg p-4 md:p-6 flex items-center justify-evenly px-6 md:px-12 py-4 md:py-8">
                <p className="text-gray-600 text-sm md:text-lg w-[30vw] hidden lg:block">
                  <Translate>{category.description}</Translate>
                </p>
                <div className="flex flex-col items-center">
                  <img

                    src={category.photo}
                    alt={category.name}
                    className="w-40 md:w-52"
                  />
                  <h3 className="text-lg md:text-xl font-bold text-gray-800 mt-2 md:mt-4 tracking-wide md:tracking-widest">
                    <Translate>{category.name}</Translate>
                  </h3>
                </div>
              </div>
            </div>

            {/* Button */}
            <div className="relative top-96 xl:top-[65vh] w-fit mx-auto">
              <button style={{ backgroundColor: categoriesData.finger1 }}
                onClick={() => navigate("/category")}
                className={`hover:bg-red-600 tracking-wider  w-52 h-12 font-bold text-white rounded-full`}
              >
                <Translate>Refer a person in need</Translate>
              </button>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default CategoriesCard; 