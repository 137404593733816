import React, { useEffect, useState } from "react";
import axios from "axios";
import ProductDetails from "./ProductDetails";
import Loading from "../Loading";
import { useParams } from "react-router-dom";
import { Translate } from "translate-easy";

const Products = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const [selectedProductId, setSelectedProductId] = useState(null);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(
                    `https://naa.dram.giize.com/api/v1/productsCategories/${id}/products?&page=${currentPage}`, {
                    withCredentials: true,
                    headers: {
                        "X-API-KEY": "naa246lan"
                    }

                },
                );
                setProducts(response.data.data);
            } catch (error) {
                console.error("Error fetching products:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchProducts();
    }, [currentPage, id]);
    const handleDetailsClick = (id) => {
        setSelectedProductId(id);
        setShowDetails(true);
    };

    const handleClose = () => setShowDetails(false);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div>
            <div className="pt-24 max-mob:pt-10 relative  dark:bg-[--black] dark:bg-opacity-4">
                <div>
                    <h1 className="text-[#EA555D] text-center w-96 max-mob:w-80 font-bold mx-auto">
                        <Translate>Clothing awareness campaigns</Translate>
                    </h1>
                </div>
                {products.length === 0 ? (
                    <p className="text-xl text-center"><Translate>There is no products.</Translate></p>
                ) : (
                    <div className="m-12 w-3/4 mx-auto flex gap-24 max-tab:justify-center max-tab:gap-16 flex-wrap relative z-30">
                        {products.map((product) => (
                            <div
                                key={product._id}
                                className="relative mt-16 w-72 bg-white h-[50vh] shadow-md shadow-gray-700 rounded-tl-full rounded-tr-full"
                            >
                                <img
                                    src={product.cover}
                                    alt="Product"
                                    className="w-full h-[40vh] -top-10 object-contain relative"
                                />
                                <div className="absolute bottom-4 left-0 right-0 text-center py-5 px-6 rounded-b-lg ">
                                    <h2 className="text-lg font-bold text-gray-800">
                                        <Translate>{product.name}</Translate>
                                    </h2>
                                </div>
                                {product?.priceAfterDiscount ? (
                                    <div className="absolute flex justify-around -bottom-4 left-0 right-0 text-center py-5 px-8 rounded-b-lg ">
                                        <p className="text-lg font-medium ml-2">
                                            £{product?.priceAfterDiscount}
                                        </p>
                                        <p className="text-lg text-red-500">
                                            <span className="text-gray-700 -ml-3 mr-1 line-through">
                                                £{product.price}
                                            </span>
                                            %{product.discount}
                                        </p>
                                    </div>
                                ) : (
                                    <div className="absolute -bottom-4 left-0 right-0 text-center py-5 px-6 rounded-b-lg ">
                                        <p className="text-lg font-medium text-gray-800">
                                            £{product?.price}
                                        </p>
                                    </div>
                                )}
                                <button
                                    className="absolute -bottom-6 left-[24%] w-40 bg-pink-100 hover:bg-pink-300 font-bold py-3 rounded-full"
                                    onClick={() => handleDetailsClick(product._id)}
                                >
                                    <Translate>Details</Translate>
                                </button>
                            </div>
                        ))}
                    </div>
                )}
                <div className="mx-auto relative z-30 w-fit py-6">
                    <button
                        className="bg-[#F13B48] text-white rounded-full w-48 h-12 "
                        onClick={() => setCurrentPage(currentPage + 1)}
                    >
                        <Translate>More</Translate>
                    </button>
                </div>

                {selectedProductId && (
                    <ProductDetails
                        show={showDetails}
                        onHide={handleClose}
                        id={selectedProductId}
                    />
                )}
            </div>
        </div>
    );
};

export default Products;
