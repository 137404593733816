import React from "react";
import gsap from "gsap";

import CurvedText from "../CurvedText";
import { Translate } from "translate-easy";
import Loading from "../Loading";
import { ScrollTrigger } from "gsap/all";
import "aos/dist/aos.css";
gsap.registerPlugin(ScrollTrigger);

export const StayTuned = ({ app, isLoading }) => {


  const [firstPart, secondPart] = app?.text ? app.text.split("\n") : ["", ""];
  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="relative bg-[--black] text-[--text]  p-4 md:p-8 lg:py-16 xl:py-20" dir="ltr">
      <div
        className="flex justify-between items-center
       max-mob:flex-wrap max-tab:justify-center mx-16 max-md:mx-8"
      >
        <div data-aos="fade-right" className="mt-14 w-[70vw] pl-44 max-md:pl-3">
          <h2 className="font-bold italic">
            <Translate>{firstPart}</Translate>
          </h2>
          <h2 className="text-[#F13B48] font-bold tracking-widest">
            <Translate>{secondPart}</Translate>
          </h2>
          <p className="font-bold italic">
            <Translate>{app?.description}</Translate>
          </p>

          <div className="flex gap-4">
            {app?.googlePhoto && (
              <a
                href={app?.googleLink || "#"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={app.googlePhoto}
                  alt="googlePhoto"
                  loading="lazy"
                  className="rounded-xl w-40"
                />
              </a>
            )}

            {app?.appPhoto && (
              <a
                href={app?.appLink || "#"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={app.appPhoto}
                  alt="appPhoto"
                  loading="lazy"
                  className="rounded-xl w-40"
                />
              </a>
            )}
          </div>
        </div>
        <div className=" w-[120vw]  max-mob:h-[61vh]" data-aos="fade-left">
          <div className="relative ">
            <div
              className="absolute inset-0 top-32 left-[35%] max-md:left-[35%]
             bg-pink-200 max-mob:w-full max-mob:left-0 w-1/2 h-1/2 opacity-50 blur-xl rounded-full -z-20"
            >
              <div className="hidden">0</div>
            </div>
            <div
              className="relative bg-no-repeat bg-cover right-[-11rem] 
              max-tab:right-[-2rem] 
              max-md:right-[0rem] 
              max-mob:right-[2rem] 
              w-[35vw] h-[70vh] 
              max-md:w-[100%] max-mob2:w-[110%] max-mob3:w-[123%]
              max-mob3:h-[59vh]
              "
              style={{
                backgroundImage: `url(${app?.photo})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <img
                src={app?.designPhoto}
                alt="logo-img"
                className="absolute top-[15.8rem] right-[3rem] w-[16.5%]
                 h-1/6 rounded-full 
                 max-tab:top-[13.8rem] max-tab:right-[1rem] max-mob:right-[-2rem] 
                 max-mob-3:top-[12.5rem] max-mob-3:right-[-1.5rem] 
                object-contain  "
                loading="lazy"
              />

              <div
                className="absolute top-[.3rem] right-[5rem] 
              max-tab:top-[.3rem] max-tab:right-[1.5rem] -z-10
              max-mob:right-[-1.5rem] max-mob3:top-[-1.5rem]
              "
              >
                <CurvedText title={app?.designText} front={false} />
              </div>
              <div
                className="absolute z-20 bottom-[8rem] right-[4.5rem]
               max-tab:top-[5.3rem] max-tab:right-[.5rem]
               max-mob:right-[-2.3rem] max-mob3:top-[2.3rem]
              "
              >
                <CurvedText title={app?.designText} front={true} />
              </div>

              <img
                src={app?.designPhoto}
                alt="logo-img"
                className="absolute bottom-[18rem] right-[19.5rem] w-[16.5%] h-1/6 rounded-full
                max-tab:bottom-[13.5rem] max-tab:right-[16.5rem] max-mob:right-[15rem] max-mob3:bottom-[12.9rem]
               object-contain "
                loading="lazy"
              />
            </div>
            {/* <img
              src={app?.photo}
              alt="phone-img"
              className="relative z-10 ml-32 max-md:ml-0 object-contain max-md:w-[50vw] max-md:mt-6"
              loading="lazy"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
